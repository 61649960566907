import React from 'react';

function MainBanner() {
  return (
    <div id='home' className='main-banner item-bg-one'>
      <div className='d-table'>
        <div className='d-table-cell'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-9 col-md-9 col-sm-12'>
                <div className=''>
                  <h1 className='theme-character-style-6 mb-5'>
                    Chief Technology Officer as-a-service
                  </h1>
                  <h4 className='theme-character-style-4'>
                    Tap into top-tier technology leadership without committing
                    to a full-time hire.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainBanner;
