import React from 'react';

import fractionalCTO from '../assets/images/FractionalCTO.png';
import projectCTO from '../assets/images/ProjectCTO.png';
import transitionalCTO from '../assets/images/TransitionalCTO.png';

function Services() {
  return (
    <section className='who-we-are ptb-80 bg-light' id='services'>
      <div className='container'>
        <div className='section-title'>
          <h4 className='theme-character-style-3'>Our</h4>
          <h2 className='theme-character-style-7'>Services</h2>
        </div>

        <div className='row'>
          <div className='col-lg-4 col-md-6 mb-5'>
            <div className='service-item'>
              <img src={fractionalCTO} alt='abstract a fractional shape' />
              <h4 className='theme-character-style-5 mt-5'>
                Fractional <br /> CTO
              </h4>
              <p className='theme-character-style-3 mt-5'>
                Draw on flexible and cost-effective technology leadership to
                help your organization stay ahead of the competition and adapt
                to changing business needs.
              </p>
            </div>
          </div>

          <div className='col-lg-4 col-md-6 mb-5'>
            <div className='service-item'>
              <img src={transitionalCTO} alt='abstract a wavy shape' />
              <h4 className='theme-character-style-5 mt-5'>
                Transitional <br /> CTO
              </h4>
              <p className='theme-character-style-3 mt-5'>
                Gain from a seasoned technology executive for a fixed period,
                providing strategic direction and tactical management to help
                achieve your operational goals faster.
              </p>
            </div>
          </div>

          <div className='col-lg-4 col-md-6 mb-5'>
            <div className='service-item'>
              <img src={projectCTO} alt='abstract a boxy shape' />
              <h4 className='theme-character-style-5 mt-5'>
                Project <br /> CTO
              </h4>
              <p className='theme-character-style-3 mt-5'>
                Unlock your organization’s full potential with a project-based
                CTO, supporting you with strategic planning and managing the
                execution of tech projects of any scale.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Services;
