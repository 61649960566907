import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import logo from '../assets/images/Logo.svg';

class Navigation extends React.Component<RouteComponentProps> {
  state: { collapsed: boolean } = {
    collapsed: true
  };

  OFFSET_AMOUNT = 95;

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  componentDidMount() {
    const elementId = document.getElementById('navbar');
    document.addEventListener('scroll', () => {
      if (window.scrollY > 170) {
        elementId?.classList.add('is-sticky');
        window.history.pushState('', document.title, window.location.pathname);
      } else {
        elementId?.classList.remove('is-sticky');
      }
    });
    window.scrollTo(0, 0);

    if (
      !(
        window.location.pathname === '/blog' ||
        window.location.pathname === '/blog-details'
      )
    ) {
      const mainNavLinks = document.querySelectorAll('nav ul li a');

      window.addEventListener('scroll', () => {
        const fromTop = window.scrollY;

        mainNavLinks.forEach((link: Element) => {
          const section: any = document.querySelector(
            (link as HTMLAnchorElement).hash
          );

          if (
            section?.offsetTop - (this.OFFSET_AMOUNT * 3) <= fromTop &&
            section?.offsetTop + section?.offsetHeight - (this.OFFSET_AMOUNT * 3) > fromTop
          ) {
            link.classList.add('active');
          } else {
            link.classList.remove('active');
          }
        });
      });
    }
  }

  goToId = (e: any) => {
    window.location.hash = e;
    window.location.reload();
  };

  renderMenus = () => (
      <ul className='navbar-nav ms-auto'>
        <li className='nav-item'>
          <AnchorLink
            onClick={this.toggleNavbar}
            offset={() => this.OFFSET_AMOUNT}
            className='nav-link theme-character-style-3'
            href='#home'
          >
            Home
          </AnchorLink>
        </li>
        <li className='nav-item'>
          <AnchorLink
            onClick={this.toggleNavbar}
            offset={() => this.OFFSET_AMOUNT}
            className='nav-link theme-character-style-3'
            href='#services'
          >
            Services
          </AnchorLink>
        </li>
        <li className='nav-item'>
          <AnchorLink
            onClick={this.toggleNavbar}
            offset={() => this.OFFSET_AMOUNT}
            className='nav-link theme-character-style-3'
            href='#capabilities'
          >
            Capabilities
          </AnchorLink>
        </li>
        <li className='nav-item'>
          <AnchorLink
            onClick={this.toggleNavbar}
            offset={() => this.OFFSET_AMOUNT}
            className='nav-link theme-character-style-3'
            href='#projects'
          >
            Projects
          </AnchorLink>
        </li>
        <li className='nav-item'>
          <AnchorLink
            onClick={this.toggleNavbar}
            offset={() => this.OFFSET_AMOUNT}
            className='nav-link theme-character-style-3'
            href='#benefits'
          >
            Benefits
          </AnchorLink>
        </li>
      </ul>
    );

  render() {
    const { collapsed } = this.state;
    const classOne = collapsed
      ? 'collapse navbar-collapse'
      : 'navbar-collapse collapse show';
    const classTwo = collapsed
      ? 'navbar-toggler navbar-toggler-right collapsed'
      : 'navbar-toggler navbar-toggler-right';
    return (
      <nav
        id='navbar'
        className='navbar navbar-expand-md navbar-light bg-light header-sticky'
      >
        <div className='container'>
          <a className='navbar-brand my-3' href='/'>
            <img src={logo} alt='openCTO logo' />
          </a>
          <button
            onClick={this.toggleNavbar}
            className={classTwo}
            type='button'
            data-toggle='collapse'
            data-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon' />
          </button>

          <div className={classOne} id='navbarSupportedContent'>
            {this.renderMenus()}
          </div>
        </div>
      </nav>
    );
  }
}

export default withRouter(Navigation);
