import ERP from '../assets/images/x1/ERP.jpg';
import AIML from '../assets/images/x1/AI_ML.jpg';
import FiveG from '../assets/images/x1/5G-in-Smart-Cities.jpg';
import BigData from '../assets/images/x1/BigData.jpg';
import IoT from '../assets/images/x1/IOT.jpg';
import Manufacturing from '../assets/images/x1/AdvancedManufacturing.jpg';
import MA from '../assets/images/x1/MA.jpg';
import Cloud from '../assets/images/x1/Cloud.jpg';
import ESG from '../assets/images/x1/ESG.jpg';
import CRM from '../assets/images/x1/CRM.jpg';
import Project from '../assets/images/x1/Project.jpg';
import CapturingValueFromData from '../assets/images/x1/Capturing-Value-from-Data.jpg';

export enum CategoryKey {
  All = 'Highlights',
  DigitalTransformation = 'Digital Transformation',
  IoTConnectivity = '5G & IoT Connectivity',
  AIData = 'AI & Data Analytics',
  SaaSCloud = 'Saas & Cloud Computing',
  ESG = 'ESG & Net-Zero Strategy',
  Performance = 'Organizational Performance'
}

export type Project = {
  title: string;
  image: string;
  description: string;
  topic: CategoryKey[];
};


export const data: Project[] = [
    {
      title: 'ERP Solution',
      description:
        'Strategic advisory and technology road mapping of a system for centralized ordering and inventory tracking, resulting in a more efficient and streamlined supply chain.',
      image: ERP,
      topic: [CategoryKey.DigitalTransformation, CategoryKey.All]
    },
    {
      title: 'CRM System',
      description:
        'Strategic consulting for a CRM system, providing real-time insights into the sales pipeline to ensure better tracking of customer interactions and boost sales productivity.',
      image: CRM,
      topic: [CategoryKey.DigitalTransformation]
    },
    {
      title: 'Advanced Manufacturing Project',
      description:
        'Business advisory and guidance on the incorporation of an advanced manufacturing system that reduced product defects and improved market competitiveness.',
      image: Manufacturing,
      topic: [CategoryKey.DigitalTransformation]
    },
    {
      title: '5G in Smart Cities',
      description:
        'Technology road mapping for a system with small cells and 5G-enabled roadside units, resulting in significant improvements in traffic flow, safety, and reduced congestion.',
      image: FiveG,
      topic: [CategoryKey.IoTConnectivity]
    },
    {
      title: 'IoT',
      description:
        'Technology consulting and execution strategy to put smart sensors on cell towers to monitor environmental factors aiming to prevent network outages, significantly reducing downtime.',
      image: IoT,
      topic: [CategoryKey.IoTConnectivity]
    },
    {
      title: 'Digital services and streaming',
      description:
        'Strategy and roadmap for the development of an AI-enhanced streaming platform with personalized recommendations and an interactive interface that scored higher on user satisfaction rankings.',
      image: Project,
      topic: [CategoryKey.IoTConnectivity, CategoryKey.All]
    },
    {
      title: 'AI/ML',
      description:
        `Solution architecture and tech roadmap on detection algorithms to identify potential threats and proactively address them, thus significantly improving the company's security reputation.`,
      image: AIML,
      topic: [CategoryKey.AIData]
    },
    {
      title: 'Big Data',
      description:
        'Sound, actionable strategic plan to implement a big data platform to better manage a large volume and variety of data assets, improving operational efficiency and reducing costs.',
      image: BigData,
      topic: [CategoryKey.AIData]
    },
    {
      title: 'Capturing Value from Data',
      description:
        'Systematic approach to identify business needs and adopt a data-driven platform to analyze product data, gain insights and address quality issues faster, improving forecasting accuracy and lowering cost overruns.',
      image: CapturingValueFromData,
      topic: [CategoryKey.AIData, CategoryKey.All]
    },
    {
      title: 'Cloud Migration',
      description:
        'Technology advisory and architecture on transforming an outdated file-sharing system to a cloud-based one, resulting in more efficient collaboration, and drastically minimizing file errors.',
      image: Cloud,
      topic: [CategoryKey.SaaSCloud]
    },
    {
      title: 'Project Service Management',
      description:
        'Strategy and guidance on streamlining HR Processes by introducing a self-service portal for employees, resulting in improved efficiency, easier access, lower workload, and cost savings.',
      image: Project,
      topic: [CategoryKey.SaaSCloud]
    },
    {
      title: 'E-commerce',
      description:
        `Advisory and technology roadmap on introducing a flexible, easy-to-use CMS, upgrading a company's product catalogue, and integrating a secure payment gateway, ultimately enhancing the customer experience.`,
      image: Project,
      topic: [CategoryKey.SaaSCloud, CategoryKey.All]
    },
    {
      title: 'Environmental Social Governance',
      description:
        `Advisory on the utilization of new technologies and tools like smart energy systems and sourcing of sustainable materials, substantially lowering costs and improving the company's reputation.`,
      image: ESG,
      topic: [CategoryKey.ESG]
    },
    {
      title: 'Environmental Monitoring',
      description:
        'Strategic planning on the implementation of multiple sensors and BI tools to track environmental factors and overall ESG KPI-s to identify areas of improvement and ensure transparency.',
      image: Project,
      topic: [CategoryKey.ESG]
    },
    {
      title: 'Compliance Consultancy',
      description:
        'Strategic consulting on when and how to appropriately make decisions regarding Environmental, Social, and Corporate Governance regulations and obtaining the ISO certificates.',
      image: Project,
      topic: [CategoryKey.ESG]
    },
    {
      title: 'Technology Due Diligence',
      description:
        'Thorough technical analysis and assessment of the buying and selling process: environment set-up, tech stack, architecture, performance, and security. Gap analysis report, recommendations, and roadmap for closing gaps.',
      image: Project,
      topic: [CategoryKey.Performance]
    },
    {
      title: 'M&A Integration Strategy',
      description:
        'Thorough strategy for change management and integration process during merger and acquisition with transparent and collaborative methods to make the operation smoother.',
      image: MA,
      topic: [CategoryKey.Performance]
    },
    {
      title: 'Scale Agile Transformation',
      description:
        'Holistic approach to PM process transformation, combining team optimization, new employee recruitment, continuous learning and development process optimization, Product & Growth strategy creation.',
      image: Project,
      topic: [CategoryKey.Performance]
    }
  ];