import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import GreyLogo from '../assets/images/Grey_logo.svg';

class Footer extends React.Component {
  OFFSET_AMOUNT = 105;

  render() {
    return (
      <footer className='footer-area'>
        <div className='container'>
          <div className='d-flex justify-content-between'>
            <div className='text-start'>
              <img src={GreyLogo} alt='openCTO logo in grey' />
              <h3 className='my-3'>
                <a href='mailto:info@opencto.com' className='text-white text-decoration-underline'>info@opencto.com</a>
              </h3>
              <p><a className="fa fa-linkedin" href="https://www.linkedin.com/company/opencto/"/>
                <span aria-hidden={true} className='invisible'>LinkedIn</span>
              </p>
            </div>
            <ul>
              <li>
                <AnchorLink href='#home' offset={() => this.OFFSET_AMOUNT}>Home</AnchorLink>
              </li>
              <li>
                <AnchorLink href='#services' offset={() => this.OFFSET_AMOUNT}>Services</AnchorLink>
              </li>
              <li>
                <AnchorLink href='#capabilities' offset={() => this.OFFSET_AMOUNT}>Capabilities</AnchorLink>
              </li>
              <li>
                <AnchorLink href='#projects' offset={() => this.OFFSET_AMOUNT}>Projects</AnchorLink>
              </li>
              <li>
                <AnchorLink href='#benefits' offset={() => this.OFFSET_AMOUNT}>Benefits</AnchorLink>
              </li>
            </ul>
          </div>
          <hr className='my-4' />
          <div className='col-lg-12 text-center'>
            <p className='text-white-50'>
              <i className='fa fa-copyright' /> open | CTO. All Rights
              Reserved {new Date().getFullYear()}
            </p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
