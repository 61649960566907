import './assets/css/bootstrap.min.css';
import 'react-modal-video/css/modal-video.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import './assets/css/font-awesome.min.css';
import './assets/css/animate.css';
import './assets/css/style.css';
import './assets/css/responsive.css';

import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import HomeOne from './components/HomeOne';

function App() {
  return (
    <Router>
      <Navigation />
      <Route path='/' exact component={HomeOne} />
    </Router>
  );
}

export default App;
