import React, { useState } from 'react';
import { CategoryKey, Project, data } from '../data/projects';

function Projects() {
  const [selectedTopic, setSelectedTopic] = useState(CategoryKey.All);

  const filterSelection = (key: CategoryKey) => () => {
    setSelectedTopic(key);
  };

  return (
    <section id='projects' className='services-area ptb-80 bg-light'>
      <div className='container'>
        <div className='section-title'>
          <h4 className='theme-character-style-3'>Our</h4>
          <h2 className='theme-character-style-7'>Projects</h2>
        </div>

        <div className='row'>
          <div className='col-lg-12 col-md-12'>
            <div className='shorting-menu mb-4 d-flex'>
              {Object.entries(CategoryKey).map(([key, displayName]) => (
                <button
                  className={`filter ${
                    displayName === selectedTopic
                      ? 'programs-filter-btn-active'
                      : ''
                  }`}
                  onClick={filterSelection(displayName)}
                  key={key}
                  type='button'
                >
                  {displayName}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className='row row-eq-height gy-4'>
          {data.map((project: Project, index: number) => {
            if (!project.topic.includes(selectedTopic)) {
              return null;
            }

            return (
              <div
                className='col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12'
                key={index}
              >
                <div className='single-services'>
                  <div
                    className='services-img'
                    style={{ backgroundImage: `url('${project.image}')` }}
                  >
                    <h3 className='theme-character-style-1 ps-3 text-start'>
                      {project.title}
                    </h3>
                  </div>

                  <div className='services-content'>
                    <p className='theme-character-style-2'>
                      {project.description}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Projects;
