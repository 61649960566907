import React from 'react';
import MainBanner from './MainBannerOne';
import Services from './Services';
import Footer from './Footer';
import GoTop from './GoTop';
import Capabilities from './Capabilities';
import Projects from './Projects';
import Benefits from './Benefits';

function HomeOne() {
  return (
    <>
      {/* Main Banner */}
      <MainBanner />

      {/* Services area */}
      <Services />

      {/* Capabilities Area */}
      <Capabilities />

      {/* Projects */}
      <Projects />

      {/* Benefits */}
      <Benefits />

      <div className='d-table'>
        <div className='d-table cell'>
          <div className='item-bg-2x' style={{ height: '20rem' }} />
        </div>
      </div>

      {/* Footer Area */}
      <Footer />
      <GoTop scrollStepInPx={100} delayInMs={16.66} />
    </>
  );
}

export default HomeOne;
